<template>
    <div class="mobile">

        <div class="content">
            <div class="logo"><img src="@/assets/mobile/XE-logo.png" alt="" class="logo"></div>
            <div class="title">
                <h1>快速、高效设计模板</h1>
                <h2>0门槛操作-简单拖拽即可完成</h2>
            </div>
            <div class="btnwrap">
            <wx-open-launch-weapp id="launch-btn" username="gh_e4cb3c633f87" path="/pages/tabbar/home">
                <script type="text/wxtag-template">
                <style>.Jump {  width: 300px;
    height: 46px;
    text-align: center;
    line-height: 46px;
    background-image: linear-gradient(to right, #293c95 , #147fc5);
    // margin: 0 auto;
    font-size: 18px;
    border-radius:26px;
    color: #fff;
    margin: 40px auto 20px;
    left:60px;
    display: block;
    border: none;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    -webkit-animation-timing-function: ease-in-out;
    -webkit-animation-name: breathe;
    -webkit-animation-duration: 1500ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-direction: alternate;}</style>
                <p class="Jump">立即使用<p>
                </script>
            </wx-open-launch-weapp>
            </div>
            <!-- <a >打开微信</a>  -->
            <a class="Jump" v-if="!isWeiXin" href="weixin://dl/business/?t=weH4G96si6q">立即使用</a>
            <div class="banner">
                <img src="../../assets/mobile/banner.png"/>
            </div>
            <div class="traitbox">
                <div class="content">
                    <h2>XE随时随地，轻松设计</h2>
                    <div class="trait trait1">
                        <div class="txt">
                            <h3>0门槛极简操作</h3>
                            <ul>
                                <li>简单拖拽替换即可制作</li>
                                <li>随时进行查看与修改</li>
                                <li>1分钟上手，5分钟出稿</li>
                            </ul>
                        </div>
                        <div class="img">
                            <img src="../../assets/mobile/trait1.png"/>
                        </div>
                    </div>
                    <div class="trait trait2">
                        <div class="img">
                            <img src="../../assets/mobile/trait2.png"/>
                        </div>
                        <div class="txt">
                            <h3>多场景化设计</h3>
                            <ul>
                                <li>H5、海报、表单素材大品类</li>
                                <li>覆盖全行业</li>
                                <li>多类型场景轻松搞定</li>
                            </ul>
                        </div>

                    </div>
                    <div class="trait trait3">
                        <div class="txt">
                            <h3>海量精美模版</h3>
                            <ul>
                                <li>海量精美模版</li>
                                <li>海量创意素材</li>
                                <li>热门创意一网打尽</li>
                            </ul>
                        </div>
                        <div class="img">
                            <img src="../../assets/mobile/trait3.png"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import { resetRem } from './utils/rem'
import wx from "weixin-js-sdk";
import wxapi from './utils/wxapi.js'
import { isWeiXin } from '@/utils/util'
export default {
    name: 'mobile',
    data() {
    return {
     isWeiXin:isWeiXin()
    };
  },
    created() {
        resetRem();
        console.log(wx)
        // console.log(isWeiXin)
    },
    mounted(){
    wxapi.wxRegister(this.wxRegCallback)

  },
  methods:{
        wxRegCallback () {
  // 用于微信JS-SDK回调
  this.wxShareTimeline()
  this.wxShareAppMessage()
},
wxShareTimeline () {
  // 微信自定义分享到朋友圈
  let option = {
    title: 'xe商城', // 分享标题, 请自行替换
    link: window.location.href.split('#')[0], // 分享链接，根据自身项目决定是否需要split
    // imgUrl: store.state.canvas.ossUrl + this.workData.workthumbnail, // 分享图标, 请自行替换，需要绝对路径
    success: () => {
      // alert('分享成功')
    },
    error: () => {
      // alert('已取消分享')
    }
  }
  // 将配置注入通用方法
  wxapi.ShareTimeline(option)
},
wxShareAppMessage () {
  // 微信自定义分享给朋友
  let option = {
    title: 'xe商城', // 分享标题, 请自行替换
    desc: '快速、高效设计模板0门槛操作-简单拖拽即可完成', // 分享描述, 请自行替换
    link: window.location.href.split('#')[0], // 分享链接，根据自身项目决定是否需要split
    // imgUrl: store.state.canvas.ossUrl + this.workData.workthumbnail, // 分享图标, 请自行替换，需要绝对路径
    success: () => {
      // alert('分享成功')
    },
    error: () => {
      // alert('已取消分享')
    }
  }
  // 将配置注入通用方法
  wxapi.ShareAppMessage(option)
},
  }
}

</script>
<style lang="less" scoped>
/* .div{
    width: 3.75rem;
    height: 2rem;
    background: #000;
} */
@-webkit-keyframes breathe {
    0% {
        opacity: .8;
        box-shadow: 0 1px 2px rgba(0, 147, 223, 0.4), 0 1px 1px rgba(0, 147, 223, 0.1) inset;
    }

    100% {
        opacity: 1;
        // border: 1px solid rgba(59, 235, 235, 0.7);
        box-shadow: 0 1px 16px #0093df, 0 1px 20px #0093df inset;
    }
}
 .btnwrap {

        display: flex;
        flex-direction: column;
        align-items: center;
    }
.mobile{
    width: 100%;
    background: #eff7ff url(../../assets/mobile/bg.png) no-repeat top;
    background-size: 100% auto;
    padding-bottom: 1.2rem;
    .content{
        padding-top: .54rem;
        min-height: 14rem;
    }
}
.logo{
    margin-left: .42rem;
    margin-bottom: .3rem;
    width: 2.6rem;
}
.title{
    text-align: center;
    color: #333333;
    h1{
        font-size: .65rem;
        font-weight: bold;

    }
    h2{

        font-size: .4rem;
        line-height: .2rem;
    }
}
.Jump{
    width: 5.34rem;
    height: .9rem;
    text-align: center;
    line-height: .9rem;
    display: block;
    background-image: linear-gradient(to right, #293c95 , #147fc5);
    // margin: 0 auto;
    font-size: .37rem;
    border-radius: 6rem;
    color: #fff;
    margin: .8rem auto .2rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    -webkit-animation-timing-function: ease-in-out;
    -webkit-animation-name: breathe;
    -webkit-animation-duration: 1500ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-direction: alternate;
}
.banner{
    width: 100%;
    margin-top:.8rem;
    img{
        width: 100%;
    }

}
.traitbox{
    background: #e4e7ed;
    padding: .3rem 0;
    width: 6.82rem;
    margin: 0 auto;
     border-radius: .4rem;
    .content{
        background: #f0f3f7;
        padding: .82rem .36rem .4rem;
        width: 6.82rem;
        min-height: auto;
        border-radius: .4rem;
    }
    h2{
        color: #293c95;
        font-size: .5rem;
        text-align: center;
        font-weight: bold;
    }
}
.trait{
    background:  url(../../assets/mobile/trait.png) no-repeat;
    background-size: 100% 100%;
    display: flex;
    height: 2.66rem;
    justify-content: space-evenly;
    align-items: center;
    border-radius: .3rem;
    margin: .6rem 0;
    h3{
        font-size: .32rem;
        font-weight: bold;
        color: #ffb001;
        text-align: left;
    }
    .txt{
        margin-top: .3rem;
        ul{
            padding: 0;
            li{
                font-size: .18rem;
                padding: .08rem 0;
                color: #7f7f7f;
            }
        }
    }
    .img{
        margin-top: .5rem;
    }
    &.trait1{
        .img{
            width: 2.25rem;
            img{
                width: 100%;
            }
        }
    }
    &.trait2{
        background:  url(../../assets/mobile/traits.png) no-repeat;
        background-size: 100% 100%;
        h3{
            text-align: right;
        }
        .img{
            width: 2.57rem;
            img{
                width: 100%;
            }
        }
    }
    &.trait3{
        .img{
            width: 2.5rem;
            img{
                width: 100%;
            }
        }
    }
}
</style>